import Swiper from "./plugins/swiper-bundle.esm.browser.min.js";

$(document).ready(function () {

    AOS.init({
        useClassNames: true,
        initClassName: 'animated',
        duration: 1000,
        offset: 50,
    });

    SmoothScroll({
        stepSize: 70,
        animationTime: 800,
        touchpadSupport   : false,
    })


    let userAgent = navigator.userAgent.toLowerCase();
    let  isIphone = /iphone/gi.test(userAgent);
    let  isIpad = /ipad/gi.test(userAgent);
    let  safari = /safari/gi.test(userAgent);

    if(isIphone || isIpad){
        $('body').addClass('ipad-iphone-support');
    }
    if (safari) $('body').addClass('safari');





    document.addEventListener('aos:out:fr-fr', ({ detail }) => {
        console.log('animated in', detail);
    });

    const header = document.getElementsByClassName(`header`)[0];
// Header scroll
    const scrollContainer = () => {
        return document.documentElement || document.body;
    };

    if (header.classList.contains("fixed")) {
        document.addEventListener("scroll", () => {
            let start = 0
            if ($('.hero-section').length) {
                start = $('.hero-section').height()
            } else {
                start = $(window).height()
            }
            if (scrollContainer().scrollTop > start) {
                header.classList.add("scrolled");
                header.querySelector('.btn').classList.remove('btn-white')
            } else if (scrollContainer().scrollTop == 0) {
                header.classList.remove("scrolled");
                header.querySelector('.btn').classList.add('btn-white')
            }
        });
    }

// menu handlers

///check mobile menu show/hide condition
    const mobileMenuStartPoint = +getComputedStyle(
        document.documentElement
    ).getPropertyValue("--mobile-menu-start-point");
    // --mobile-menu-start-point value is in _variables.pcss it is mobile breakpoint
    let isMobileMenuEnable =
        $(window).outerWidth() <= mobileMenuStartPoint || $(".header-mobile").length;

    $(".dropdown-toggle").click(function (e) {
        if (isMobileMenuEnable) {
            //close all opened sub menu
            $(".menu-item.dropdown.active .dropdown-menu").slideUp({
                complete: function () {
                    $(this).closest(".dropdown").removeClass("active");
                },
            });

            //open current submenu
            $(this).closest(".menu-item.dropdown").toggleClass("active");
            if ($(this).closest(".menu-item.dropdown").hasClass("active")) {
                e.preventDefault();
                $(this).next(".dropdown-menu").slideDown();
            }
        }
    });


    //fix mobile menu height because of bottom system navigation panel
    if(isMobileMenuEnable){
        let navbar = document.querySelector('.navbar-nav');
        if(navbar){
            let vh = window.innerHeight * 0.01;
            navbar.style.setProperty('--vh', `${vh}px`);
            window.addEventListener('resize',()=>{
                let vh = window.innerHeight * 0.01;
                navbar.style.setProperty('--vh', `${vh}px`);
            })
        }
    }


// toggle menu handler
    function menuToggle() {
        $(".menu-toggle").toggleClass("active");
        $(".navbar-nav").toggleClass("active");
        $(".header-close-wrapper").toggleClass("active");
        // LockScroll when burger open and enable when closed and enable scroll on menu
        scrollLock.getScrollState()
            ? scrollLock.disablePageScroll(document.querySelector(".navbar-nav .menu"))
            : scrollLock.enablePageScroll();
    }

//menu update function
    function updateMenu() {
        isMobileMenuEnable =
            $(window).outerWidth() <= mobileMenuStartPoint ||
            $(".mobile-header").length;
        if (!isMobileMenuEnable) {
            $(".dropdown-menu").css("display", "");
            $(".header-close-wrapper").removeClass("active");
            $(".menu-item.active").removeClass("active");
            $(".navbar-nav").removeClass("active");
            $(".menu-toggle").removeClass("active");
            // LockScroll when burger open and enable when closed
            scrollLock.enablePageScroll();
        }
    }

    $(window).on("resize orientationchange", updateMenu);
// end of toggle menu handler

    $(".menu-toggle").click(menuToggle); //menu toggles
    $(".header-close-wrapper").click(menuToggle); //menu toggles


    var elements = document.querySelectorAll(".force-sticky");
    Stickyfill.forceSticky();
    Stickyfill.add(elements);

    // ------------------ project code ------------

    $('.select2-item').each((idx, el)=>{
        $(el).select2();
        $(el).on('select2:select', function(e){
           if($(el).val()!=''){
               $(el).parent().find('.select2-placeholder').hide();
           }else{
               $(el).parent().find('.select2-placeholder').show();
           }
        })
        $(el).on('select2:unselect', function(e){
            if($(el).val()!=''){
                $(el).parent().find('.select2-placeholder').hide();
            }else{
                $(el).parent().find('.select2-placeholder').show();
            }
        })
    })

    //bold logo adaptive replace

    let mbReplace = false;

    function boldLogo() {
        if ($(window).width() <= mobileMenuStartPoint && !mbReplace) {
            mbReplace = true;
            $('#bold-credits').appendTo('.footer');
        } else if (mbReplace && $(window).width() > mobileMenuStartPoint) {
            mbReplace = false;
            $('#bold-credits').appendTo('.footer .footer-social');
        }
    }

    $(window).on('resize', boldLogo);
    boldLogo();


    function animateElements(selector, animationName = 'fadeIn', duration = 1500, delay = 0, multiplyDelay = 0) {

        let animationArray = ['slideLeft', 'slideRight', 'slideDown', 'fadeIn', 'slideUp', 'clip-down'];
        if (!animationArray.includes(animationName)) {
            animationName = 'fadeIn';
        }
        if (!$(selector).length) {
            console.log(`Message - animated element-(${selector}) doesn't exists `);
            return
        }
        let parentElement;
        // if set multiply animation, check parent position then start animation for all children
        if (multiplyDelay) {
            parentElement = $(selector).parent()[0];
        }
        $(window).on('scroll', () => {
            let animationDelay = delay;
            $(selector).each((ind, el) => {
                let newElement = $(el);
                if (parentElement) {
                    newElement = parentElement;
                }
                $(el).addClass('animated'); //  .beforeAnimation{opacity: 0;}
                if ($(window).scrollTop() > $(newElement).offset().top - ($(window).height() * 0.98) &&
                    $(window).scrollTop() < $(newElement).offset().top + $(newElement).height() - ($(window).height() * 0.10)
                ) {
                    $(el).css({
                        'animation-delay': `${animationDelay}ms`,
                        'animation-duration': `${duration}ms`
                    })
                    $(el).addClass(animationName);
                    animationDelay += multiplyDelay;
                }
            })
        });
        $(window).trigger('scroll')
    }

    // Common animation
    animateElements('.clip-title', 'clip-down');


    // Parallax effect
    function parallaxEffect(parent, elements) {
        // Magic happens here
        function parallax(e) {
            let width = window.innerWidth / 2;
            let height = window.innerHeight / 2;
            let mouseX = e.clientX;
            let mouseY = e.clientY;
            let firstPos = `${50 - (mouseX - width) * 0.03}px`;
            let secondPos = `${50 - (mouseY - height) * 0.03}px`;
            if (elements.length) {
                elements.forEach((item, i) => {
                    if (i % 2 === 0) {
                        item.style.transform = `translate(${firstPos}, ${secondPos})`;
                    } else {
                        item.style.transform = `translate(-${firstPos}, -${secondPos})`;
                    }
                });
            }
        }

        $(document).on("mousemove", `${parent}`, parallax);

    }

    const parallaxItems = document.querySelectorAll(".ts-figure img");
    const parallaxItems2 = document.querySelectorAll(".section-figure-layers .figure img");

    parallaxEffect('.testimonial-item', parallaxItems);
    parallaxEffect('.review-swiper', parallaxItems2);
    parallaxEffect('.review-section', parallaxItems2);



    let specialismSwiperWr = document.querySelectorAll('.specialism-swiper-wr');
    specialismSwiperWr.forEach(el => {
        if (el) {
            let swiperBlock = el.querySelector('.swiper');
            let nextEl = el.querySelector('.swiper-button-next')
            let prevEl = el.querySelector('.swiper-button-prev')
            let slidesCount = swiperBlock.querySelectorAll('.swiper-slide').length;
            const swiper = new Swiper(swiperBlock, {
                speed: 800,
                autoplay:{
                    delay: 5000,
                },
                navigation: {
                    nextEl: nextEl,
                    prevEl: prevEl,
                },
                breakpoints:{
                    320:{
                        slidesPerView: 1,
                        loop: slidesCount > 1,
                        spaceBetween: 15,
                    },
                    650:{
                        slidesPerView: 2,
                        loop: slidesCount > 2,
                        spaceBetween: 20,
                    },
                    990:{
                        slidesPerView: 3,
                        loop: slidesCount > 3,
                        spaceBetween: 30,
                    },

                }
            })
        }
    })

    let teamSwiperWr = document.querySelectorAll('.team-swiper-wr');
    teamSwiperWr.forEach(el => {
        if (el) {
            let swiperBlock = el.querySelector('.swiper');
            let nextEl = el.querySelector('.swiper-button-next')
            let prevEl = el.querySelector('.swiper-button-prev')
            let slidesCount = swiperBlock.querySelectorAll('.swiper-slide').length;
            const swiper = new Swiper(swiperBlock, {
                speed: 800,
                autoplay:{
                    delay: 5000,
                },
                threshold:15,
                navigation: {
                    nextEl: nextEl,
                    prevEl: prevEl,
                },
                breakpoints:{
                    280:{
                        slidesPerView: 1,
                        loop: slidesCount > 1,
                        spaceBetween: 30,
                    },
                    650:{
                        slidesPerView: 3,
                        loop: slidesCount > 3,
                        spaceBetween: 30,
                    },
                    992:{
                        slidesPerView: 4,
                        loop: slidesCount > 4,
                        spaceBetween: 30,
                    },

                },
                on: {
                    slideChange: function(){
                    $('.teammate-card-read-more-info.active').slideUp().removeClass('.active')
                        $('.teammate-card .read-more-btn').removeClass('active')
                    }
                }
            })
            $('body').on('click', '.teammate-card .read-more-btn', function(){
                $(this).toggleClass('active');
                $(this).next('.teammate-card-read-more-info').toggleClass('active');
                $(this).next('.teammate-card-read-more-info').slideToggle(300, function(){
                    if($(this).is(':visible')){
                        swiper.autoplay.stop()
                    }else{
                        swiper.autoplay.start()
                    }
                })

            })
        }
    })




    let reviewSwiperWr = document.querySelectorAll('.review-swiper-wr');
    reviewSwiperWr.forEach(el => {
        if (el) {
            let swiperBlock = el.querySelector('.swiper');
            let nextEl = el.querySelector('.swiper-button-next')
            let prevEl = el.querySelector('.swiper-button-prev')
            let slidesCount = swiperBlock.querySelectorAll('.swiper-slide').length;
            const swiper = new Swiper(swiperBlock, {
                loop: slidesCount>1,
                autoplay:{
                    delay: 5000,
                },
                effect: 'fade',
                autoHeight: true,
                fadeEffect:{
                    crossFade: true,
                },
                navigation: {
                    nextEl: nextEl,
                    prevEl: prevEl,
                }
            })
        }
    })

    let recentSwiperWr = [...$('.recent-job-swiper-wr')];
    recentSwiperWr.forEach(el=>{
            if(el){
                let swiperBlock =  el.querySelector('.swiper');
                let nextEl = el.querySelector('.swiper-button-next')
                let prevEl = el.querySelector('.swiper-button-prev')
                let slidesCount = swiperBlock.querySelectorAll('.swiper-slide').length;
                const swiper = new Swiper(swiperBlock, {
                    slidesPerView: 3,
                    spaceBetween:15,
                    loop: slidesCount > 3,
                    speed: 800,
                    navigation:{
                        nextEl: nextEl,
                        prevEl: prevEl,
                    },
                    breakpoints:{
                        280:{
                            slidesPerView: 'auto',
                            loop: slidesCount > 1,
                            centeredSlides: true,
                        },
                        500:{
                            slidesPerView: 'auto',
                            loop: slidesCount > 2,
                        },
                        920:{
                            slidesPerView: 'auto',
                            loop: slidesCount > 3,
                        },
                        1250:{
                            slidesPerView: 3,
                            loop: slidesCount > 3,
                        }
                    }
                })
            }
        })

    let file = document.querySelector('[type="file"]');
    if (file) {
        file.addEventListener('change', function () {
                $('.upload-status').text(this.files[0].name);
            }
        )
    }

    $('.role-card .role-card-caption').on('click', function () {
        $(this).closest('.role-card').toggleClass('open');
        $(this).closest('.role-card').find('.role-card-content-hidden').slideToggle();
    })
    // $('.clients-page .role-card .role-card-caption').eq(0).trigger('click')
     // $('.candidate-page .role-card .role-card-caption').eq(1).trigger('click')

    $('.btn-filter').on('click', function(){
        $('.btn-filter').removeClass('active');
        $(this).addClass('active')
    })


}) //end of ready